// Guard for the names of uploaded files.  See backend/src/models.py for
// details.
export const FILE_NAME_LIMIT = 512;

export const FormMapper = {
  // All the "themes" are automagically generated from backend's
  // util.py::THEME_VISION_DICT.
  theme1:
    "Responsible AI: To support the development of AI systems that are safe, secure and trusted.",
  core1: "Cognitive modelling and systems",
  core2: "Game theory and economic paradigms",
  core3: "Heuristic search and optimization",
  core4: "Knowledge representation and reasoning",
  core5: "Machine Learning",
  core6: "Multiagent Systems",
  core7: "Natural Language Processing (NLP)",
  core8: "Planning and Scheduling",
  core9: "Reasoning under uncertainty",
  core10: "Robotics",
  core11: "Search and constraint satisfaction",
  core12: "Vision",
  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  "create-cares":
    "Campus for Research Excellence And Technological Enterprise (CREATE) - CARES",
  "create-sec":
    "Campus for Research Excellence And Technological Enterprise (CREATE) - Singapore-ETH",
  PI: "Principal Investigator",
  "PI[SG]": "Principal Investigator (Singapore)",
  "PI[US]": "Principal Investigator (US)",
  "Co-PI": "Co Principal Investigator",
  "Co-PI[SG]": "Co Principal Investigator (Singapore)",
  "Co-PI[US]": "Co Principal Investigator (US)",
  collab: "Collaborator",
  "collab[SG]": "Collaborator (Singapore)",
  "collab[US]": "Collaborator (US)",
  "in-kind": "In Kind",
  cash: "Cash",
};

export const SysMapper = {
  draft: "Draft",
  "complete-un": "Completed (Unendorsed)",
  reject: "Rejected",
  conditional_award: "Conditional Offer",
  "complete-en": "Completed (Endorsed)",
  "revise-cluster": "Revision Request (Host Institution)",
  "revise-aisg-r": "Revision Request",
  "revise-aisg-c": "Revision Completed",
  proceed: "Proceed to Stage 2",
  awarded: "Awarded",
  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  "create-cares":
    "Campus for Research Excellence And Technological Enterprise (CREATE) - CARES",
  "create-sec":
    "Campus for Research Excellence And Technological Enterprise (CREATE) - Singapore-ETH",
  aisg: "AI Singapore",
  "reviewer-local": "Reviewer (Local)",
  "reviewer-inter": "Reviewer (International)",
  "admin-super": "Administrator (Superuser)",
  "admin-cluster": "Administrator (Cluster)",
  submitter: "User (Submission)",
  technology: "AI Technology",
  innovation: "AI Innovation",
  research: "AI Research",
};

export const RevMapper = {
  support_outcome: {
    support: "Supported",
    "not-support": "Not Supported",
  },
  scientific: {
    4: "Excellent",
    3: "Good",
    2: "Average",
    1: "Poor",
    0: "Unable to Judge/Not Applicable",
  },
  novelty: {
    4: "Excellent",
    3: "Good",
    2: "Average",
    1: "Poor",
    0: "Unable to Judge/Not Applicable",
  },
  feasibility: {
    4: "Excellent",
    3: "Good",
    2: "Average",
    1: "Poor",
    0: "Unable to Judge/Not Applicable",
  },
  suitability: {
    4: "Excellent",
    3: "Good",
    2: "Average",
    1: "Poor",
    0: "Unable to Judge/Not Applicable",
  },
  impact: {
    4: "Excellent",
    3: "Good",
    2: "Average",
    1: "Poor",
    0: "Unable to Judge/Not Applicable",
  },
  // Specific to NSF.
  bilateral: {
    4: "Excellent",
    3: "Good",
    2: "Average",
    1: "Poor",
    0: "Unable to Judge/Not Applicable",
  },
  overall_score: {
    4: "Excellent: Proposal is of outstanding quality, I would like to highly recommend it to being funded.",
    3: "Good: Proposal is of good quality, I think it could be funded.",
    2: "Average: Proposal is lacking in some areas, but it has merits that outweigh its flaws. It can be considered after certain issues are addressed.",
    1: "Poor: Proposal is lacking in one or more critical aspect, key issues are not sufficiently addressed. Its flaws outweigh its merits. Hence, it is insufficiently competitive and below the acceptance threshold.",
    0: "Unable to Judge.",
  },
  manpower: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  equipment: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  other_operating: {
    4: "Adequate",
    3: "Excessive",
    2: "Inadequate",
    1: "Unable to Judge",
  },
  level_of_confidence: {
    4: "Expert in the research area.",
    3: "Familiar with the topic/research area.",
    2: "Somewhat familiar with the topic/research area.",
    1: "Not familiar with the topic/research area – can review for general AI topic of interest.",
  },
  relevance_of_proposal: {
    4: "Very relevant to AI Core Technical Areas",
    3: "Relevant to the AI Core Technical Areas",
    2: "Somewhat relevant to the AI Core Technical Areas, but too domain specific.",
    1: "Not relevant to the AI Core Technical Areas at all.",
  },
  relevance_to_themes: {
    4: "Very relevant to proposed theme",
    3: "Relevant to proposed theme",
    2: "Somewhat relevant to proposed theme, but too domain specific.",
    1: "Not relevant to any proposed theme at all.",
  },
};

/* Proposal */
export const proposal = {
  title: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "enter a title for the proposal (max 200 characters)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.1] Title",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "<Title>",
    valid: false,
    touched: false,
  },

  period_of_support: {
    elementType: "input-inline",
    elementConfig: {
      type: "number",
      placeholder: "enter number of years (numerical only)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.2] Period of Support",
      sublabel: "(Number of years)",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: false,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  grand_total: {
    elementType: "input-inline",
    elementConfig: {
      type: "number",
      placeholder: "enter amount in Singapore dollars (numerical only)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.3] Grand Total Cost for Singapore",
      sublabel: "(Direct + Indirect Research)",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: true,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  grand_total_us: {
    elementType: "input-inline",
    elementConfig: {
      type: "number",
      placeholder: "enter amount in US dollars (numerical only)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.4] Grand Total Cost for US",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isDecimal: true,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  host_institution: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "<----- Please select from drop-down menu ----->" },
        {
          value: "a-star",
          label: "Agency for Science, Technology and Research (A*STAR)",
        },
        { value: "nus", label: "National University of Singapore (NUS)" },
        { value: "ntu", label: "Nanyang Technological University (NTU)" },
        { value: "smu", label: "Singapore Management University (SMU)" },
        {
          value: "sutd",
          label: "Singapore University of Technology and Design (SUTD)",
        },
        { value: "sit", label: "Singapore Institute of Technology (SIT)" },
        {
          value: "suss",
          label: "Singapore University of Social Sciences (SUSS)",
        },
        {
          value: "create-cares",
          label:
            "Campus for Research Excellence And Technological Enterprise (CREATE) - CARES",
        },
        {
          value: "create-sec",
          label:
            "Campus for Research Excellence And Technological Enterprise (CREATE) - Singapore-ETH",
        },
      ],
      isValid: false,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[SECTION 1.5] Host Institution",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },

  host_institution_us: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "(up to 200 characters)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.6] US Host Institution",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  theme: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        //{
        //  value: "",
        //  label: "<------ Please select a vision from drop-down ------>",
        //},
        // Obtained from hoc/FormConfig.js with lots of search + replace.
        {
          value: "theme1",
          label:
            "Responsible AI: To support the development of AI systems that are safe, secure and trusted.",
        },
        //{ value: "theme2", label: "Towards Human Omnitasking" },
        //{ value: "theme3", label: "Removing Unwanted Digital Footprints" },
        //{ value: "theme4", label: "AI for Science" },
      ],
      isValid: true,
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "[SECTION 1.7] Theme of the Proposal",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },

  //open_theme: {
  //  elementType: "input-inline",
  //  elementConfig: {
  //    type: "text",
  //    placeholder: "(input exmple: opentheme1,opentheme2,opentheme3)",
  //    isValid: false,
  //    isInvalid: false,
  //    disabled: true,
  //  },
  //  elementDecorators: {
  //    label: "[SECTION 1.6] Open Theme: AI Fundamental Research",
  //    sublabel: "Specify up to THREE (3) keywords)",
  //    feedback: "valid",
  //    feedbackMsg: "",

  //  },
  //  validation: {
  //    isText: true,
  //    maxLength: 200,
  //    required: false,
  //  },
  //  value: "",
  //  valid: true,
  //  touched: false,
  //},

  //theme_others: {
  //  elementType: "input-inline",
  //  elementConfig: {
  //    type: "text",
  //    placeholder: "(input exmple: othertopic1,othertopic2,othertopic3...)",
  //    isValid: false,
  //    isInvalid: false,
  //    disabled: false,
  //  },
  //  elementDecorators: {
  //    label: "[SECTION 1.7] Other Sub-Theme(s)",
  //    sublabel: "",
  //    feedback: "valid",
  //    feedbackMsg: "",
  //  },
  //  validation: {
  //    isText: true,
  //    maxLength: 200,
  //    required: false,
  //  },
  //  value: "",
  //  valid: true,
  //  touched: false,
  //},
  core_tech_areas: {
    elementType: "checkboxes-inline",
    elementConfig: {
      choices: [
        { value: "core1", label: "Cognitive modelling and systems" },
        { value: "core2", label: "Game theory and economic paradigms" },
        { value: "core3", label: "Heuristic search and optimization" },
        { value: "core4", label: "Knowledge representation and reasoning" },
        { value: "core5", label: "Machine learning" },
        { value: "core6", label: "Multiagent systems" },
        { value: "core7", label: "Natural language processing (NLP)" },
        { value: "core8", label: "Planning and scheduling" },
        { value: "core9", label: "Reasoning under uncertainty" },
        { value: "core10", label: "Robotics" },
        { value: "core11", label: "Search and constraint satisfaction" },
        { value: "core12", label: "Vision" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label:
        "[SECTION 1.8] Classification of AI Core Technical Areas of the Proposal",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: [],
    validation: {
      maxChoices: 3,
      required: true,
    },
    valid: false,
    touched: false,
  },

  // These are new and are attached to the Team Information part in the MSWord
  // template.
  existing_funded_award: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "enter the award number (max 200 characters)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.9] Existing Singapore Funded Award Number",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  existing_funded_award_us: {
    elementType: "input-inline",
    elementConfig: {
      type: "text",
      placeholder: "enter the award number (max 200 characters)",
      isValid: false,
      isInvalid: false,
      required: true,
      disabled: false,
    },
    elementDecorators: {
      label: "[SECTION 1.10] Existing US Funded Award Number",
      sublabel: "",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  team_information: {
    elementType: "input-cascade",
    elementConfig: {
      data: {
        header: {
          role: {
            label: "Role",
            sublabel: "",
            config: {
              type: "select",
              options: [
                { value: "", label: "(select a role from drop-down)" },
                {
                  value: "PI[SG]",
                  label: "Principal Investigator (Singapore)",
                },
                {
                  value: "PI[US]",
                  label: "Principal Investigator (US)",
                },
                {
                  value: "Co-PI[SG]",
                  label: "Co-Principal Investigator (Singapore)",
                },
                {
                  value: "Co-PI[US]",
                  label: "Co-Principal Investigator (US)",
                },
                { value: "collab[SG]", label: "Collaborator (Singapore)" },
                { value: "collab[US]", label: "Collaborator (US)" },
              ],
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          firstname: {
            label: "First Name",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Given Name",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          lastname: {
            label: "Last Name",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "Family Name",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          gscholar: {
            label: "Google Scholar",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: false,
            },
            value: "",
            valid: true,
            touched: false,
            validation: {
              maxLength: 200,
              required: false,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          dblp: {
            label: "DBLP",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: false,
            },
            value: "",
            valid: true,
            touched: false,
            validation: {
              maxLength: 200,
              required: false,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          institution: {
            label: "Institution",
            sublabel:
              "For Singapore PI's / Co-PI's institution: write in short-form only, e.g., A*STAR, NTU, NUS, CREATE-CARES, CREATE-SEC.",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          designation: {
            label: "Designation",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g Associate Professor",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          department: {
            label: "Department",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          percentage_effort: {
            label: "Percentage Effort %",
            sublabel: "",
            config: {
              type: "number",
              placeholder: "(max 10 characters)",
              required: true,
            },
            value: "0",
            valid: false,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          percentage_time: {
            label: "Percentage Time %",
            sublabel: "",
            config: {
              type: "number",
              placeholder: "(max 10 characters)",
              required: true,
            },
            value: "0",
            valid: false,
            touched: false,
            validation: {
              isDecimal: true,
              maxLength: 10,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
        },
        content: [],
      },
      type: "stack",
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[SECTION 1.11] Team Members",
      sublabel:
        "Each team should have at least 1 Principal Investigator from each country.",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },

  proposal: {
    elementType: "upload",
    label: "[SECTION 2] Details of Research Proposal (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  budget: {
    elementType: "upload",
    label: "[SECTION 3] Proposed Budget (max 16mb)",
    sublabel: "(Please only upload .xlsx format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  funding_support: {
    elementType: "upload",
    label: "[SECTION 4] Declaration of Other Funding Support (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  performance_indicators: {
    elementType: "upload",
    label: "[SECTION 5] Performance Indicators (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  international_reviewers: {
    elementType: "input-cascade",
    elementConfig: {
      data: {
        header: {
          name: {
            label: "Name of Reviewer",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              isText: true,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          institution: {
            label: "Institution",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "(max 200 characters)",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          designation: {
            label: "Designation",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g Associate Professor",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          email: {
            label: "E-mail address",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "e.g abc@xyz.com",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          interest: {
            label: "Research interest / Expertise",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
          relationship: {
            label: "Relationship to PI / Co-PI",
            sublabel: "",
            config: {
              type: "text",
              placeholder: "",
              required: true,
            },
            value: "",
            valid: false,
            touched: false,
            validation: {
              maxLength: 200,
              required: true,
            },
            feedback: {
              type: "valid",
              message: "",
            },
          },
        },
        content: [],
      },
      type: "stack",
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "[SECTION 6] Names of Suggested International Reviewers",
      sublabel: "Please nominate at least three(3) reviewers",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },

  supplement_files: {
    elementType: "upload",
    label:
      "[SECTION 7.1] Declaration by Grant Applicants (Singapore) (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  supplement_files_us: {
    elementType: "upload",
    label: "[SECTION 7.2] Declaration by Grant Applicants (US) (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  // supplement_endorsement: {
  //   elementType: "upload",
  //   label: "[Section 8] Endorsement by the Host Institution",
  //   sublabel: "(Please only upload .pdf format)",
  //   feedback: "",
  //   value: "",
  //   valid: true,
  //   touched: false,
  //   loaded: true,
  //   validation: {
  //     required: false,
  //   },
  // },

  project_schedule: {
    elementType: "upload",
    label: "[APPENDIX A] Project Implementation Schedule (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  curriculum_vitae: {
    elementType: "upload",
    label: "[APPENDIX B] Curriculum Vitae (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  letter_of_support: {
    elementType: "upload",
    label:
      "[APPENDIX C] Declaration of Other Funding Support Supplementary (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },

  description_theme: {
    elementType: "upload",
    label: "[APPENDIX D] Description of Proposal Theme (max 16mb)",
    sublabel: "(Please only upload .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
};

export const reviews = {
  // SECTION A
  scientific: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Excellent" },
        { value: "3", label: "3 - Good" },
        { value: "2", label: "2 - Average" },
        { value: "1", label: "1 - Poor" },
        { value: "0", label: "0 - Unable to Judge/Not Applicable" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Scientific Quality",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 0,
  },

  novelty: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Excellent" },
        { value: "3", label: "3 - Good" },
        { value: "2", label: "2 - Average" },
        { value: "1", label: "1 - Poor" },
        { value: "0", label: "0 - Unable to Judge/Not Applicable" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Novelty",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 0,
  },

  feasibility: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Excellent" },
        { value: "3", label: "3 - Good" },
        { value: "2", label: "2 - Average" },
        { value: "1", label: "1 - Poor" },
        { value: "0", label: "0 - Unable to Judge/Not Applicable" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Feasibility / Viability / Appropriateness",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 0,
  },

  suitability: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Excellent" },
        { value: "3", label: "3 - Good" },
        { value: "2", label: "2 - Average" },
        { value: "1", label: "1 - Poor" },
        { value: "0", label: "0 - Unable to Judge/Not Applicable" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Suitability and track record of PI and research team",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 0,
  },

  impact: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Excellent" },
        { value: "3", label: "3 - Good" },
        { value: "2", label: "2 - Average" },
        { value: "1", label: "1 - Poor" },
        { value: "0", label: "0 - Unable to Judge/Not Applicable" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Impact to the field of AI",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 0,
  },

  bilateral: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Excellent" },
        { value: "3", label: "3 - Good" },
        { value: "2", label: "2 - Average" },
        { value: "1", label: "1 - Poor" },
        { value: "0", label: "0 - Unable to Judge/Not Applicable" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Bilateral research integration level",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 0,
  },

  manpower: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "Adequate" },
        { value: "3", label: "Excessive" },
        { value: "2", label: "Inadequate" },
        { value: "1", label: "Unable to Judge" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Manpower",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 1,
  },

  equipment: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "Adequate" },
        { value: "3", label: "Excessive" },
        { value: "2", label: "Inadequate" },
        { value: "1", label: "Unable to Judge" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Equipment / Facilities",
      sublabel: "(includes compute resources)",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 1,
  },

  other_operating: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "Adequate" },
        { value: "3", label: "Excessive" },
        { value: "2", label: "Inadequate" },
        { value: "1", label: "Unable to Judge" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Other Operating Expenditure",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "a",
    part: 1,
  },

  // SECTION B
  support_justification: {
    elementType: "textarea-inline",
    elementConfig: {
      type: "text",
      placeholder:
        "(Please provide comments on, (i) Scientific quality (ii) Novelty (iii) Feasibility / Viability / Appropriateness (iv) Suitability and track record of PI and research team (v) Impact to the field of AI (vi) Resources Requested.)",
      isValid: false,
      isInvalid: false,
      required: true,
      as: "textarea",
      rows: "7",
    },
    elementDecorators: {
      label: "Basis / Justifications for Overall Scoring / Recommendation",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 5000,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
    section: "b",
    part: 1,
  },

  // SECTION C
  overall_score: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        {
          value: "4",
          label:
            "4 - Excellent: Proposal is of outstanding quality, I would like to highly recommend it to being funded.",
        },
        {
          value: "3",
          label:
            "3 - Good: Proposal is of good quality, I think it could be funded.",
        },
        {
          value: "2",
          label:
            "2 - Average: Proposal is lacking in some areas, but it has some merits that only slightly outweigh its flaws. Certain nontrivial issues need to be addressed before it can be considered.",
        },
        {
          value: "1",
          label:
            "1 - Poor: Proposal is lacking in one or more critical aspect, key issues are not sufficiently addressed. Its flaws outweigh its merits. Hence, it is insufficiently competitive and below the acceptance threshold.",
        },
        { value: "0", label: "0 - Unable to Judge" },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Overall Scoring / Recommendation",
      sublabel:
        "Scoring of 3 & 4 would indicate recommended for funding, 2 would indicate borderline but leaning slightly towards not recommended for funding, and 1 would indicate not recommended for funding.",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "c",
    part: 1,
  },

  level_of_confidence: {
    elementType: "select-inline",
    elementConfig: {
      options: [
        { value: "", label: "(select from drop-down)" },
        { value: "4", label: "4 - Expert in the research area." },
        { value: "3", label: "3 - Familiar with the topic/research area." },
        {
          value: "2",
          label: "2 - Somewhat familiar with the topic/research area.",
        },
        {
          value: "1",
          label:
            "1 - Not familiar with the topic/research area – can review for general AI topic of interest.",
        },
      ],
      isValid: false,
      isInvalid: false,
    },
    elementDecorators: {
      label: "Level of Confidence in Reviewing this Proposal",
      feedback: "valid",
      feedbackMsg: "",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    section: "c",
    part: 2,
  },

  // SECTION D
  strength_of_proposal: {
    elementType: "textarea-inline",
    elementConfig: {
      type: "text",
      placeholder:
        "(Please provide constructive comments on the ​strengths​ of the proposal.)",
      isValid: false,
      isInvalid: false,
      required: true,
      as: "textarea",
      rows: "7",
    },
    elementDecorators: {
      label: "Strengths of the Proposal",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 5000,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
    section: "d",
    part: 1,
  },

  suggestion_for_improvement: {
    elementType: "textarea-inline",
    elementConfig: {
      type: "text",
      placeholder:
        "(Please provide constructive comments on the ​weaknesses​ of the proposal. How can the proposal be modified, augmented, reduced or structured for greater impact?)",
      isValid: false,
      isInvalid: false,
      required: true,
      as: "textarea",
      rows: "7",
    },
    elementDecorators: {
      label: "Suggestions for Improvement",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isText: true,
      maxLength: 5000,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
    section: "d",
    part: 2,
  },
};

export const endorse = {
  endorse_file: {
    elementType: "file",
    elementConfig: {
      type: "file",
      isInvalid: false,
      required: true,
    },
    elementDecorators: {
      label: "Endorsement Page (Upload)",
      feedback: "valid",
      feedbackMsg: "",
    },
    validation: {
      isFile: true,
    },
    value: "",
    selectedFile: null,
    valid: false,
    touched: false,
  },
};
